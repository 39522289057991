import React from "react"
import styled from "styled-components"
import { Page } from "../../shared/Page/Page"
import { Header } from "../../shared/Header/Header"
import { PageHeader } from "../../shared/PageHeader/PageHeader"
import { Footer } from "../../shared/Footer/Footer"
import { FlatButton } from "../../shared/Ui/FlatButton/FlatButton"
import { Link } from "gatsby"

const Container = styled.div`
    section.content {
        display: flex;
        padding: 40rem 0 100rem;
        margin-top: 20rem;

        box-shadow: ${({ theme }) => theme.surfaces.dark} 0 -50rem 50rem -50px
            inset;

        > .container {
            flex: 1;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 20rem;

            .option {
                display: flex;
                flex-direction: column;
                background: ${({ theme }) => theme.surfaces.lighter};
                padding: 30rem 35rem;
                max-width: 300rem;
                border-radius: 10rem;
                border: 1rem solid ${({ theme }) => theme.borders.normal};
                gap: 10rem;
                box-shadow: rgba(149, 157, 165, 0.2) 0 8rem 24rem;

                svg {
                    width: 100rem;
                    height: 100rem;
                }

                .title {
                    font-size: 25rem;
                    color: ${({ theme }) => theme.text.darker};
                    font-weight: 500;
                    font-family: Gilroy-Bold, sans-serif;
                }

                .description {
                    font-size: 16rem;
                    line-height: 26rem;
                    color: ${({ theme }) => theme.text.light};
                    margin-bottom: 10rem;
                }

                > a {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }
`

const Support = ({ location }) => {
    return (
        <Page disableHeader disableFooter disableCta location={location}>
            <Header constrained />
            <PageHeader
                h1={"Support"}
                h2={"We're standing by ready to help"}
                className={"pageConstrained"}
            />
            <Container>
                <section className={"content overlapPageMargins"}>
                    <div className={"container pageConstrained"}>
                        <div className={"option"}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="url(#brandGradient)"
                            >
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path d="M5 13.18v4L12 21l7-3.82v-4L12 17l-7-3.82zM12 3L1 9l11 6 9-4.91V17h2V9L12 3z" />
                            </svg>
                            <div className={"title"}>Knowledge base</div>
                            <div className={"description"}>
                                Self serve articles and collections for how to
                                use 23shout with in-depth explanations of
                                features and strategies.
                            </div>
                            <a href={"https://help.23shout.com"} tabIndex={-1}>
                                <FlatButton
                                    variant={"flat"}
                                    color={"primary"}
                                    size={"large"}
                                    justify={"center"}
                                    fontWeight={600}
                                >
                                    Open knowledge base
                                </FlatButton>
                            </a>
                        </div>

                        <div className={"option"}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="url(#brandGradient)"
                            >
                                <path d="M0 0h24v24H0V0z" fill="none" />
                                <path d="M20 6h-1v8c0 .55-.45 1-1 1H6v1c0 1.1.9 2 2 2h10l4 4V8c0-1.1-.9-2-2-2zm-3 5V4c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v13l4-4h9c1.1 0 2-.9 2-2z" />
                            </svg>
                            <div className={"title"}>Live chat</div>
                            <div className={"description"}>
                                Always willing to chat, feel free to contact us
                                here regarding anything, from sales, to support,
                                and general inquiries.
                            </div>
                            <FlatButton
                                variant={"flat"}
                                color={"primary"}
                                size={"large"}
                                justify={"center"}
                                fontWeight={600}
                                onClick={() => Intercom("show")}
                            >
                                Start a conversation
                            </FlatButton>
                        </div>

                        <div className={"option"}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                fill="url(#brandGradient)"
                            >
                                <path d="M0 0h24v24H0V0z" fill="none" />
                                <path d="M19.23 15.26l-2.54-.29c-.61-.07-1.21.14-1.64.57l-1.84 1.84c-2.83-1.44-5.15-3.75-6.59-6.59l1.85-1.85c.43-.43.64-1.03.57-1.64l-.29-2.52c-.12-1.01-.97-1.77-1.99-1.77H5.03c-1.13 0-2.07.94-2 2.07.53 8.54 7.36 15.36 15.89 15.89 1.13.07 2.07-.87 2.07-2v-1.73c.01-1.01-.75-1.86-1.76-1.98z" />
                            </svg>
                            <div className={"title"}>Call us</div>
                            <div className={"description"}>
                                Prefer to call? Us to. Give us a bell on our
                                1800 number, if the team is unavailable just
                                leave a message and we'll call you back.
                            </div>
                            <a href={"tel:+611800951343"}>
                                <FlatButton
                                    variant={"flat"}
                                    color={"primary"}
                                    size={"large"}
                                    justify={"center"}
                                    fontWeight={600}
                                >
                                    (+61) 1800 951 343
                                </FlatButton>
                            </a>
                        </div>
                    </div>
                </section>
            </Container>
            <Footer constrained />
        </Page>
    )
}

export default Support
